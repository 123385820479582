import { h } from 'preact';
import style from './style.css';

const Header = ({left = null, center = null, right = null}) => (
	<header class={style.header}>
		<div class={style.left}>{left}</div>
		<div class={style.center}>{center}</div>
		<div class={style.right}>{right}</div>
	</header>
);

export default Header;
