import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/CheckCircleOutline';
import CheckBorder from '@material-ui/icons/PanoramaFishEye';
import LibraButton from '../../../components/button';
import IconChevronRight from '@material-ui/icons/ChevronRight';
import { useHistory } from "react-router-dom";
import Headline from '../../../components/Headline/Headline';
import { withStyles, makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { dataSetAno } from '../../../redux/actions/data';

const MyButton = withStyles(theme => ({
    root: {
        backgroundColor: 'white',
        padding: '0.8rem'
    },
    label: {
        fontSize: '1rem'
    }
}))(Button);

const useStyles = makeStyles(theme => ({
    active: {
        backgroundColor: 'white !important',
        color: `${theme.palette.primary.main} !important`,
        fontWeight: 'bold'
    }
}));

const Ano = ({ ano, dataSetAno }) => {
    const [selected, setSelected] = useState(ano);

    const classes = useStyles();
    const history = useHistory();
    const anos = [];
    const currAno = new Date().getFullYear();
    for (let i = currAno; i >= (currAno - 14); i--) {
        anos.push(i);
    }

    const handleSubmit = () => {
        return dataSetAno(selected);
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Headline>Seleccioná el año</Headline>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {anos.map(a => (
                        <Grid item xs={4}>
                            <MyButton
                                variant={'contained'}
                                fullWidth
                                className={selected === a ? classes.active : ''}
                                onClick={evt => {
                                    setSelected(a);
                                }}
                            >{a}</MyButton>
                        </Grid>)
                    )}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox icon={<CheckBorder />} checkedIcon={<Check />} color={'primary'} onChange={evt => evt.target.checked ? setSelected('older') : setSelected(null)} checked={selected === 'older'} />}
                    label={<Typography>Mi vehiculo es anterior al año {currAno - 14}</Typography>}
                />
            </Grid>

            <Grid item xs={12}>
                <LibraButton disabled={!selected} onClick={handleSubmit} fullWidth startIcon={<span style={{ width: 20 }} />} endIcon={<IconChevronRight />}>Siguiente</LibraButton>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        ano: state.data.ano
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dataSetAno: x => dispatch(dataSetAno(x))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Ano);