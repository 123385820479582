import { h } from 'preact';
import { useState } from 'preact/hooks';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import LibraButton from '../../../components/button';
import IconChevronRight from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/CheckCircleOutline';
import CheckBorder from '@material-ui/icons/PanoramaFishEye';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Headline from '../../../components/Headline/Headline';

const useStyles = makeStyles({
    tableCell: {
        border: 'none'
    },
    bolder: {
        fontWeight: 'bold'
    }
});


const Confirmacion = ({
    marca,
    modelo,
    ano,
    gnc,
    nombre,
    apellido,
    provincia,
    codigoPostal,
    email,
}) => {
    const history = useHistory();
    const classes = useStyles();
    const [tyc, setTyc] = useState(false);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Headline>Confirmemos los datos</Headline>
            </Grid>

            <Grid item xs={12}>
                <Paper>
                    <Box p={1}>
                        <Table aria-label="simple table" size={'small'}>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableCell}>Marca</TableCell>
                                    <TableCell align="right" className={clsx([classes.tableCell, classes.bolder])}>{marca}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableCell}>Modelo</TableCell>
                                    <TableCell align="right" className={clsx([classes.tableCell, classes.bolder])}>{modelo}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableCell}>Año</TableCell>
                                    <TableCell align="right" className={clsx([classes.tableCell, classes.bolder])}>{ano}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableCell}>GNC</TableCell>
                                    <TableCell align="right" className={clsx([classes.tableCell, classes.bolder])}>{gnc === 'si' ? 'Con GNC' : 'Sin GNC'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableCell}>Tu nombre</TableCell>
                                    <TableCell align="right" className={clsx([classes.tableCell, classes.bolder])}>{nombre} {apellido}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableCell}>Provincia</TableCell>
                                    <TableCell align="right" className={clsx([classes.tableCell, classes.bolder])}>{provincia}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableCell}>Código postal</TableCell>
                                    <TableCell align="right" className={clsx([classes.tableCell, classes.bolder])}>{codigoPostal}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableCell}>Email</TableCell>
                                    <TableCell align="right" className={clsx([classes.tableCell, classes.bolder])}>{email}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel
                    onChange={evt => setTyc(evt.target.checked)}
                    style={{ alignItems: 'flex-start' }}
                    control={<Checkbox icon={<CheckBorder />} checkedIcon={<Check />} color={'primary'} />}
                    label={<Typography variant={'body2'} style={{ paddingTop: 8 }}>He leído y acepto los <a href="#" target="_blank">términos y condiciones</a> y políticas de privacidad</Typography>}
                />
            </Grid>

            <Grid item xs={12}>
                <LibraButton
                    onClick={() => history.push('/propuestas')}
                    fullWidth
                    startIcon={<span style={{ width: 20 }} />}
                    endIcon={<IconChevronRight />}
                    disabled={!tyc}
                >Cotizar</LibraButton>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        marca: state.data.marca,
        modelo: state.data.modelo,
        ano: state.data.ano,
        gnc: state.data.gnc,
        nombre: state.data.nombre,
        apellido: state.data.apellido,
        provincia: state.data.provincia,
        codigoPostal: state.data.codigoPostal,
        email: state.data.email,
    }
}

export default connect(mapStateToProps, null)(Confirmacion);