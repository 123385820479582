import { h } from 'preact';
import { useState } from 'preact/hooks';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LibraButton from '../../../components/button';
import IconChevronRight from '@material-ui/icons/ChevronRight';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';
import Headline from '../../../components/Headline/Headline';
import { connect } from 'react-redux';
import { dataSetMarca } from '../../../redux/actions/data';

const marcas = [
    { title: 'Chery' },
    { title: 'Chevrolet' },
    { title: 'Chrysler' },
]

const Marca = ({ marca, dataSetMarca }) => {
    const history = useHistory();
    const [selected, setSelected] = useState(marca);

    const handleSubmit = () => {
        return dataSetMarca(selected);
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Headline>Seleccioná la marca de tu auto</Headline>
            </Grid>

            <Grid item xs={12}>
                <Autocomplete
                    value={{ title: selected || '' }}
                    disableClearable
                    fullWidth
                    options={marcas}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} label={'Marca'} variant={'outlined'} color={'primary'} fullWidth />}
                    onChange={(evt, value) => setSelected(value.title)}
                />
            </Grid>

            <Grid item xs={12}>
                <LibraButton
                    onClick={handleSubmit}
                    fullWidth
                    startIcon={<span style={{ width: 20 }} />}
                    endIcon={<IconChevronRight />}
                    disabled={!selected}
                >Siguiente</LibraButton>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        marca: state.data.marca
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dataSetMarca: marca => dispatch(dataSetMarca(marca))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Marca);