import { h } from 'preact';
import { useState } from 'preact/hooks';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import style from './style.scss';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import IconArrow from '@material-ui/icons/ExpandMore';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Create';
import { connect } from 'react-redux';
import { dataSetStep } from '../../redux/actions/data';
import EditorStepper from '../EditorStepper/EditorStepper';

const Editor = () => {
    const [open, setOpen] = useState(false);

    const handleClick = evt => {
        setOpen(!open);
    }

    return (
        <Box className={clsx([style.editor, open ? style.open : null])}>
            <Paper className={style.panel} elevation={3}>
                <Box className={style.header} onClick={handleClick}>
                    <Typography style={{ textAlign: 'left', fontWeight: 'bold' }}>Editar selección</Typography>
                    <IconArrow color={'primary'} className={style.icon} />
                </Box>
                <Box className={style.content}>
                    <Box pb={5}>
                        <EditorStepper />
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

export default Editor