import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

import Home from './routes/Home/Home';
import Cotizacion from './routes/Cotizacion/Cotizacion';
import Propuestas from './routes/Propuestas/Propuestas';
import DatosPoliza from './routes/DatosPoliza/DatosPoliza';
import DatosPago from './routes/DatosPago/DatosPago';
import DescargarPoliza from './routes/DescargarPoliza/DescargarPoliza';

import { Provider, connect } from 'react-redux';
import store from './store';

const customTheme = createMuiTheme({
	typography: {
		fontFamily: 'PT Sans, arial, sans-serif',
	},
	palette: {
		common: {
			black: '#495566',
			white: '#ffffff'
		},
		primary: {
			light: '#FF8E00',
			main: '#FF8E00',
			dark: '#FF8E00',
			contrastText: '#ffffff'
		},
		secondary: {
			light: '#495566',
			main: '#495566',
			dark: '#495566',
			contrastText: '#ffffff'
		},
		warning: {
			light: '#ffffff',
			main: '#ffffff',
			dark: '#ffffff',
			contrastText: '#FF8E00'
		},
		text: {
			primary: '#495566'
		},
		action: {
			active: '#000000'
		}
	}
});

const App = () => {
	return (
		<Provider store={store}>
			<ThemeProvider theme={customTheme}>
				<div id="app">
					<Router>
						<ScrollToTop />
						<Switch>
							<Route exact path="/">
								<Home />
							</Route>
							<Route exact path="/cotizacion">
								<Cotizacion />
							</Route>
							<Route exact path={'/propuestas'}>
								<Propuestas />
							</Route>
							<Route exact path={'/datos-poliza'}>
								<DatosPoliza />
							</Route>
							<Route exact path={'/datos-pago'}>
								<DatosPago />
							</Route>
							<Route exact path={'/descargar-poliza'}>
								<DescargarPoliza />
							</Route>
						</Switch>
					</Router>
				</div>
			</ThemeProvider>
		</Provider>
	)
}

export default App;
