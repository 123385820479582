import { h } from 'preact';
import { useState } from 'preact/hooks';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/CheckCircleOutline';
import CheckBorder from '@material-ui/icons/PanoramaFishEye';
import LibraButton from '../../components/button';
import IconChevronRight from '@material-ui/icons/ChevronRight';
import Header from '../../components/header';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tarjetas from '../../assets/tarjetas.png';
import Logo from '../../assets/logo-final.svg';
import Paper from '@material-ui/core/Paper';
import IconGetApp from '@material-ui/icons/GetApp';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';

const DescargarPoliza = ({ nombre }) => {
    return (
        <Container maxWidth={'sm'}>
            <Box py={5} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: '100vh' }}>
                <Grid container spacing={4} style={{ flex: 1, flexDirection: 'column' }}>
                    <Grid item xs={12}>
                        <Typography variant={'h5'} style={{ textAlign: 'center', fontWeight: 'bold' }}>¡Felicitaciones {nombre}!</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <Paper>
                                <Box p={3} component={'a'} href={'https://www.libraseguros.com.ar/'} style={{ display: 'block' }}>
                                    <img src={Logo} style={{ display: 'block' }} />
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align={'center'}>Ya tenés tu póliza de <strong>Responsabilidad Civil <br /> Nro. 12312312323</strong></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <LibraButton
                                    fullWidth
                                    startIcon={<span style={{ width: 20 }} />}
                                    endIcon={<IconGetApp />}
                                >Descargar póliza</LibraButton>
                            </Grid>
                            <Grid item xs={12}>
                                <LibraButton fullWidth>Descargar Libra App</LibraButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align={'center'}>Con cualquiera de las dos en tu celular<br /> ya podés circular.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 'auto' }}>
                        <LibraButton variant={'outlined'} color={'primary'} style={{ textTransform: 'initial' }} fullWidth>¿Dudas? Contactanos</LibraButton>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        nombre: state.data.nombre
    }
}


export default connect(mapStateToProps, null)(DescargarPoliza);