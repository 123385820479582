import { h } from 'preact';
import { useState } from 'preact/hooks';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import style from '../Editor/style.scss';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import IconArrow from '@material-ui/icons/ExpandMore';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Create';
import { connect } from 'react-redux';
import { dataSetStep } from '../../redux/actions/data';

const EditButton = ({ onClick }) => {
    return (
        <IconButton color={'inherit'} aria-label="delete" style={{position: 'absolute',top: 0, right: 0}} onClick={onClick}>
            <EditIcon style={{ color: '#757575' }} />
        </IconButton>
    )
}

const EditorStepper = ({
    step,
    ano,
    marca,
    modelo,
    gnc,
    modificaciones,
    nombre,
    apellido,
    provincia,
    codigoPostal,
    telefono,
    canalContactoPreferencia,
    email,
    dataSetStep
}) => {

    const handlerOnClickEditStep = step => evt => {
        dataSetStep(step);
        setOpen(false);
    }

    return (
        <Stepper orientation="vertical">
            <Step key={'ano'} active={step > 1} completed={step > 1} className={style.step}>
                <StepLabel>Año</StepLabel>
                <StepContent style={{position: 'relative'}}>
                    <Typography>{ano}</Typography>
                    <EditButton onClick={handlerOnClickEditStep(1)} />
                </StepContent>
            </Step>
            <Step key={'marca'} active={step > 2} completed={step > 2} className={style.step}>
                <StepLabel>Marca de tu vehiculo</StepLabel>
                <StepContent style={{position: 'relative'}}>
                    <Typography>{marca}</Typography>
                    <EditButton onClick={handlerOnClickEditStep(2)} />
                </StepContent>
            </Step>
            <Step key={'modelo'} active={step > 3} completed={step > 3} className={style.step}>
                <StepLabel>Modelo de tu vehiculo</StepLabel>
                <StepContent style={{position: 'relative'}}>
                    <Typography>{modelo}</Typography>
                    <EditButton onClick={handlerOnClickEditStep(3)} />
                </StepContent>
            </Step>
            <Step key={'extras'} active={step > 4} completed={step > 4} className={style.step}>
                <StepLabel>GNC / Modificaciones</StepLabel>
                <StepContent style={{position: 'relative'}}>
                    <Typography>{gnc === 'si' ? 'Con GNC' : 'Sin GNC'}</Typography>
                    <Typography>{modificaciones}</Typography>
                    <EditButton onClick={handlerOnClickEditStep(4)} />
                </StepContent>
            </Step>
            <Step key={'datos-personales'} active={step > 5} completed={step > 5} className={style.step}>
                <StepLabel>Datos personales</StepLabel>
                <StepContent style={{position: 'relative'}}>
                    <Typography>
                        {nombre} {apellido}<br />
                        {provincia} - {codigoPostal}
                    </Typography>
                    <EditButton onClick={handlerOnClickEditStep(5)} />
                </StepContent>
            </Step>
            <Step key={'informacion-contacto'} active={step > 6} completed={step > 6} className={style.step}>
                <StepLabel>Información de contacto</StepLabel>
                <StepContent style={{position: 'relative'}}>
                    <Typography>{telefono}</Typography>
                    <Typography>Contactar: {canalContactoPreferencia}</Typography>
                    <Typography>{email}</Typography>
                    <EditButton onClick={handlerOnClickEditStep(6)} />
                </StepContent>
            </Step>
        </Stepper>
    )
}

const mapStateToProps = state => {
    return {
        step: state.data.step,
        ano: state.data.ano,
        marca: state.data.marca,
        modelo: state.data.modelo,
        gnc: state.data.gnc,
        modificaciones: state.data.modificaciones,
        nombre: state.data.nombre,
        apellido: state.data.apellido,
        provincia: state.data.provincia,
        codigoPostal: state.data.codigoPostal,
        telefono: state.data.telefono,
        canalContactoPreferencia: state.data.canalContactoPreferencia,
        email: state.data.email
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dataSetStep: step => dispatch( dataSetStep(step) )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorStepper);