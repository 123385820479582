import { createStore, applyMiddleware } from 'redux';
import AppReducers from './redux/reducers';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loadState, saveState } from './localStorage';

const persistedState = loadState();

const store = createStore(AppReducers, persistedState, composeWithDevTools(applyMiddleware(thunk)));

store.subscribe(() => {
    saveState({ data: store.getState().data });
});

export default store;