import { h, Fragment } from 'preact';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import style from './style.scss';
import clsx from 'clsx';

const MainAccessButton = ({icon, activeIcon, label, active, ...others}) => {
	return (
		<Button fullWidth variant={'contained'} className={clsx([style.root, active ? style.isActive : null])} {...others}>
			<Box className={style.inner}>
				<Box mb={2} className={style.iconWrapper}>{active ? activeIcon : icon}</Box>
				<Box mb={-3}><Typography className={style.label}>{label}</Typography></Box>
			</Box>
		</Button>
	)
}

export default MainAccessButton;