import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { withRouter, useHistory } from 'react-router-dom';

const ScrollToTop = ({ location, children }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return children;
}

export default withRouter(ScrollToTop);