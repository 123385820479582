export const DATA_SET_TIPO_COTIZACION = 'DATA_SET_TIPO_COTIZACION';
export const DATA_SET_ANO = 'DATA_SET_ANO';
export const DATA_SET_MARCA = 'DATA_SET_MARCA';
export const DATA_SET_MODELO = 'DATA_SET_MODELO';
export const DATA_SET_EXTRAS = 'DATA_SET_EXTRAS';
export const DATA_SET_DATOS_PERSONALES = 'DATA_SET_DATOS_PERSONALES';
export const DATA_SET_INFO_CONTACTO = 'DATA_SET_INFO_CONTACTO';
export const DATA_RESET = 'DATA_RESET';
export const DATA_SET_STEP = 'DATA_SET_STEP';
export const DATA_SET_DATOS_POLIZA = 'DATA_SET_DATOS_POLIZA';
export const DATA_SET_DATOS_PAGO = 'DATA_SET_DATOS_PAGO';