import { h } from 'preact';
import { useState } from 'preact/hooks';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/CheckCircleOutline';
import CheckBorder from '@material-ui/icons/PanoramaFishEye';
import LibraButton from '../../../components/button';
import IconChevronRight from '@material-ui/icons/ChevronRight';
import Header from '../../../components/header';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { dataSetExtras } from '../../../redux/actions/data';
import Headline from '../../../components/Headline/Headline';
import { filterSpecialChars } from '../../../utils';

const ButtonStyled = withStyles(theme => ({
    root: {
        width: 120,
        backgroundColor: theme.palette.grey[50],
        '&:hover': {
            backgroundColor: theme.palette.grey[50],
            zIndex: 99
        },
        '&.active': {
            backgroundColor: theme.palette.primary.main,
        },
        '&.active .MuiButton-label': {
            color: 'white'
        }
    },
    label: {
        fontWeight: 'bold'
    }
}))(Button);

const Extras = ({ gnc, modificaciones, dataSetExtras }) => {
    const history = useHistory();
    
    const [localValues, setLocalValues] = useState({
        gnc: gnc,
        modificaciones: modificaciones
    });

    const handleSubmit = () => {
        return dataSetExtras({ gnc: localValues.gnc, modificaciones: localValues.modificaciones });
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Headline>¿Tu auto tiene GNC?</Headline>
            </Grid>

            <Grid item xs={12}>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} flex={1}>
                    <ButtonGroup variant="contained" color={'default'} style={{ borderRadius: 200, overflow: 'hidden' }}>
                        <ButtonStyled onClick={evt => setLocalValues({...localValues, gnc: 'si'})} className={clsx({ 'active': localValues.gnc === 'si' })}>Si</ButtonStyled>
                        <ButtonStyled onClick={evt => setLocalValues({...localValues, gnc: 'no'})} className={clsx({ 'active': localValues.gnc === 'no' })}>No</ButtonStyled>
                    </ButtonGroup>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    placeholder={'Modificaciones o customizaciones'}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={6}
                    color={'primary'}
                    helperText="La suma asegurada del equipo de GNC es de $40.000.-"
                    onChange={evt => setLocalValues({
                        ...localValues, 
                        modificaciones: evt.target.value
                    })}
                    value={localValues.modificaciones || ''}
                />
            </Grid>

            <Grid item xs={12}>
                <LibraButton
                    disabled={!localValues.gnc}
                    fullWidth
                    startIcon={<span style={{ width: 20 }} />}
                    endIcon={<IconChevronRight />}
                    onClick={handleSubmit}
                >Siguiente</LibraButton>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        gnc: state.data.gnc,
        modificaciones: state.data.modificaciones
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dataSetExtras: extras => dispatch(dataSetExtras(extras))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Extras);