import { h } from 'preact';
import Header from '../header';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import style from './style.scss';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import IconHelp from '@material-ui/icons/HelpOutline';

export default ({ step, onClickBack }) => {
    return (
        <Box style={{ position: 'sticky', top: 0, zIndex: 9 }}>
            <Header
                left={<IconButton component="span" onClick={onClickBack}><ArrowBack color={'primary'} /></IconButton>}
                center={<Typography style={{ textAlign: 'left', fontWeight: 'bold' }}>{`Paso ${step} de 7`}</Typography>}
                // right={<IconButton component="span"><IconHelp color={'primary'} /></IconButton>}
            />
            <span className={style.bar} style={{ width: `${100 / 7 * step}%` }} />
        </Box>
    )
}