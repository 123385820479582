import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LibraButton from '../../../components/button';
import IconChevronRight from '@material-ui/icons/ChevronRight';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { dataSetInfoContacto } from '../../../redux/actions/data';
import Headline from '../../../components/Headline/Headline';
import validate from 'validate.js';

const ButtonStyled = withStyles(theme => ({
    root: {
        width: 120,
        backgroundColor: theme.palette.grey[50],
        '&:hover': {
            backgroundColor: theme.palette.grey[50],
            zIndex: 99
        },
        '&.active': {
            backgroundColor: theme.palette.primary.main,
        },
        '&.active .MuiButton-label': {
            color: 'white'
        }
    },
    label: {
        fontWeight: 'bold'
    }
}))(Button);


const InformacionContacto = ({ dataSetInfoContacto, telefono, canalContactoPreferencia, email }) => {

    const [formValues, setFormValues] = useState({
        telefono: telefono,
        email: email,
        canalContactoPreferencia: canalContactoPreferencia
    });

    const [formErrors, setFormErrors] = useState({
        telefono: '',
        email: '',
        canatlContactoPreferencia: ''
    });

    const handleSubmit = () => {
        return dataSetInfoContacto({
            telefono: formValues.telefono,
            email: formValues.email,
            canalContactoPreferencia: formValues.canalContactoPreferencia
        });
    }

    useEffect(() => {
        setFormErrors(
            validate(formValues, {
                telefono: {
                    presence: { allowEmpty: false },
                    type: 'string',
                    format: /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/
                },
                email: {
                    email: true,
                    presence: { allowEmpty: false },
                }
            }) || {}
        )
    }, [formValues]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Headline>Información de contacto</Headline>
            </Grid>
            
            <Grid item xs={12}>
                <TextField
                    type={'number'}
                    label="Teléfono"
                    InputProps={{ startAdornment: <InputAdornment position="start">+54</InputAdornment> }}
                    variant={'outlined'}
                    fullWidth
                    placeholder={'Ej: 1155556666'}
                    helperText={'Sin guiones, ni espacios. Incluye el código de área de tu ciudad.'}
                    onChange={evt => setFormValues({ ...formValues, telefono: evt.target.value })}
                    value={formValues.telefono}
                    error={!!(formErrors.telefono && formValues.telefono)}
                />
            </Grid>

            <Grid item xs={12}>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} flex={1} mb={3}>
                    <Box>
                        <Box mb={1}>
                            <Typography variant={'body2'} align={'center'} style={{ fontWeight: 'bold' }}>Canal de contacto de preferencia</Typography>
                        </Box>
                        <ButtonGroup variant="contained" color={'default'} style={{ borderRadius: 200, overflow: 'hidden' }}>
                            <ButtonStyled onClick={evt => setFormValues({ ...formValues, canalContactoPreferencia: 'llamado' })} className={clsx({ 'active': formValues.canalContactoPreferencia === 'llamado' })}>Llamado</ButtonStyled>
                            <ButtonStyled onClick={evt => setFormValues({ ...formValues, canalContactoPreferencia: 'whatsapp' })} className={clsx({ 'active': formValues.canalContactoPreferencia === 'whatsapp' })}>WhatsApp</ButtonStyled>
                        </ButtonGroup>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Email"
                    variant={'outlined'}
                    fullWidth
                    helperText={'Tu póliza te va a llegar a este mail apenas termines tu compra.'}
                    onChange={evt => setFormValues({ ...formValues, email: evt.target.value })}
                    value={formValues.email}
                    type={'email'}
                    error={!!(formErrors.email && formValues.email)}
                />
            </Grid>

            <Grid item xs={12}>
                <LibraButton
                    onClick={handleSubmit}
                    fullWidth
                    startIcon={<span style={{ width: 20 }} />}
                    endIcon={<IconChevronRight />}
                    disabled={Object.keys(formErrors).length > 0}
                >Siguiente</LibraButton>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        telefono: state.data.telefono,
        canalContactoPreferencia: state.data.canalContactoPreferencia,
        email: state.data.email
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dataSetInfoContacto: infoContacto => dispatch(dataSetInfoContacto(infoContacto))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InformacionContacto);