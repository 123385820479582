import { PROPUESTA_GET_PLANES } from '../constants/propuesta';

export const propuestaGetPlanes = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            return resolve({
                data: [{
                    id: 'p1',
                    featured: true
                },{
                    id: 'p2',
                    featured: true
                },{
                    id: 'p3',
                },{
                    id: 'p4',
                },{
                    id: 'p5',
                },{
                    id: 'p6',
                }]
            });
        }, 1000);
    });
}