import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LibraButton from '../../../components/button';
import IconChevronRight from '@material-ui/icons/ChevronRight';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { dataSetDatosPersonales } from '../../../redux/actions/data';
import Headline from '../../../components/Headline/Headline';
import { filterSpecialChars, filterName } from '../../../utils';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import validate from 'validate.js';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

const provincias = new Array("Buenos Aires", "CABA", "Catamarca", "Chaco", "Chubut", "Cordoba", "Corrientes", "Entre Rios", "Formosa", "Jujuy", "La Pampa", "La Rioja", "Mendoza", "Misiones", "Neuquen", "Rio Negro", "Salta", "San Juan", "San Luis", "Santa Cruz", "Santa Fe", "Santiago del Estero", "Tierra del Fuego", "Tucuman");

const DatosPersonales = ({ nombre, apellido, provincia, codigoPostal, dataSetDatosPersonales }) => {
    const history = useHistory();

    const [formValues, setFormValues] = useState({
        nombre: nombre,
        apellido: apellido,
        provincia: provincia,
        codigoPostal: codigoPostal
    });

    const [formErrors, setFormErrors] = useState({});

    const handleSubmit = () => {
        return dataSetDatosPersonales({
            nombre: formValues.nombre,
            apellido: formValues.apellido,
            provincia: formValues.provincia,
            codigoPostal: formValues.codigoPostal
        });
    }

    useEffect(() => {
        setFormErrors(
            validate(formValues, {
                nombre: {
                    presence: {
                        allowEmpty: false
                    },
                    type: 'string',
                    format: /^[a-z ,.'-]+$/i
                },
                apellido: {
                    presence: {
                        allowEmpty: false
                    },
                    type: 'string',
                    format: /^[a-z ,.'-]+$/i
                },
                apellido: {
                    presence: {
                        allowEmpty: false
                    },
                    type: 'string',
                    format: /^[a-z ,.'-]+$/i
                },
                apellido: {
                    presence: {
                        allowEmpty: false
                    },
                    type: 'string'
                }
            }) || {}
        )
    }, [formValues]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Headline>Algunos datos personales</Headline>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField 
                            label="Nombre" 
                            variant="outlined" 
                            fullWidth 
                            value={formValues.nombre || ''} 
                            onChange={evt => setFormValues({ ...formValues, nombre: evt.target.value })} 
                            error={!!(formErrors.nombre && formValues.nombre)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            label="Apellido" 
                            variant="outlined" 
                            fullWidth 
                            value={formValues.apellido || ''} 
                            onChange={evt => setFormValues({ ...formValues, apellido: filterSpecialChars(evt.target.value) })} 
                            error={!!(formErrors.apellido && formValues.apellido)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="demo-simple-select-label">Provincia</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formValues.provincia || ''}
                                onChange={evt => setFormValues({ ...formValues, provincia: evt.target.value })}
                                label="Provincia"
                                error={!!(formErrors.provincia && formValues.provincia)}
                                // IconComponent={() => <ArrowDropDown />}
                            >
                                {provincias.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField 
                            label="Código Postal" 
                            variant="outlined" 
                            fullWidth 
                            value={formValues.codigoPostal || ''} 
                            onChange={evt => setFormValues({ ...formValues, codigoPostal: evt.target.value })} 
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <LibraButton
                    onClick={handleSubmit}
                    fullWidth
                    startIcon={<span style={{ width: 20 }} />}
                    endIcon={<IconChevronRight />}
                    disabled={!(formValues.nombre && formValues.apellido && formValues.provincia && formValues.codigoPostal)}
                >Siguiente</LibraButton>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        nombre: state.data.nombre,
        apellido: state.data.apellido,
        provincia: state.data.provincia,
        codigoPostal: state.data.codigoPostal
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dataSetDatosPersonales: datosPersonales => dispatch(dataSetDatosPersonales(datosPersonales))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatosPersonales);