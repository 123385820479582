import { h } from 'preact';
import { useState } from 'preact/hooks';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LibraButton from '../../../components/button';
import IconChevronRight from '@material-ui/icons/ChevronRight';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';
import Headline from '../../../components/Headline/Headline';
import { connect } from 'react-redux';
import { dataSetModelo } from '../../../redux/actions/data';

const modelos = [
    { title: 'Corsa' },
    { title: 'Corvette' },
    { title: 'Cobalt' },
]

const Modelo = ({ modelo, dataSetModelo }) => {
    const history = useHistory();
    const [selected, setSelected] = useState(modelo);

    const handleSubmit = () => {
        return dataSetModelo(selected);
    };

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Headline>Seleccioná el modelo</Headline>
            </Grid>

            <Grid item xs={12}>
                <Autocomplete
                    value={{ title: selected || ''}}
                    disableClearable
                    fullWidth
                    options={modelos}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <TextField {...params} label={'Modelo'} variant={'outlined'} color={'primary'} fullWidth />}
                    onChange={(evt, value) => setSelected(value.title)}
                />
            </Grid>

            <Grid item xs={12}>
                <LibraButton
                    onClick={handleSubmit}
                    fullWidth
                    startIcon={<span style={{ width: 20 }} />}
                    endIcon={<IconChevronRight />}
                    disabled={!selected}
                >Siguiente</LibraButton>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        modelo: state.data.modelo
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dataSetModelo: modelo => dispatch(dataSetModelo(modelo))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modelo)