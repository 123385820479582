import { DATA_SET_ANO, DATA_SET_TIPO_COTIZACION, DATA_SET_MARCA, DATA_SET_MODELO, DATA_SET_EXTRAS, DATA_SET_DATOS_PERSONALES, DATA_SET_INFO_CONTACTO, DATA_RESET, DATA_SET_STEP, DATA_SET_DATOS_POLIZA } from '../constants/data';

const baseState = {
    step: 1,
    tipoCotizacion: null,
    ano: null,
    marca: null,
    modelo: null,
    gnc: null,
    modificaciones: null,
    nombre: null,
    apellido: null,
    provincia: null,
    codigoPostal: null,
    telefono: null,
    canalContactoPreferencia: 'llamado',
    email: null,
    dni: '',
    fechaNacimiento: '',
    sexo: '',
    calle: '',
    calleNumero: '',
    pisoDpto: '',
    localidad: '',
    patente: '',
    colorVehiculo: '',
    numeroChasis: '',
    numeroMotor: ''
}

export default (state = baseState, action) => {
    switch(action.type){
        case DATA_SET_TIPO_COTIZACION:
            return {
                ...state,
                tipoCotizacion: action.payload
            }
            break;
        case DATA_SET_ANO:
            return {
                ...state,
                ano: action.payload,
                step: 2
            }
            break;
        case DATA_SET_MARCA:
            return {
                ...state,
                marca: action.payload,
                step: 3
            }
            break;
        case DATA_SET_MODELO:
            return {
                ...state,
                modelo: action.payload,
                step: 4
            }
            break;
        case DATA_SET_EXTRAS:
            return {
                ...state,
                ...action.payload,
                step: 5
            }
            break;
        case DATA_SET_DATOS_PERSONALES:
            return {
                ...state,
                ...action.payload,
                step: 6
            }
            break;
        case DATA_SET_INFO_CONTACTO:
            return {
                ...state,
                ...action.payload,
                step: 7
            }
            break;
        case DATA_SET_STEP:
            return {
                ...state,
                step: action.payload
            }
            break;
        case DATA_SET_DATOS_POLIZA:
            return {
                ...state,
                ...action.payload,
            }
            break;
        case DATA_RESET:
            return {
                ...baseState
            }
            break;
        default:
            return state
    }
}