import { h } from 'preact';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const Button = withStyles(theme => ({
    root: {
        borderRadius: 500,
    },
    label: {
        fontWeight: 'bold',
        flex: 1,
        justifyContent: 'center',
        textAlign: 'center',
        letterSpacing: '0.06em'
    },
    startIcon: {
        marginRight: 'auto'
    },
    endIcon: {
        marginLeft: 'auto'
    }
}))(MuiButton);

export default ({ children, ...others }) => {
    return (
        <Button
            size={'large'}
            color={'primary'}
            variant={'contained'}
            disableElevation
            {...others}
        >{children}</Button>
    )
}