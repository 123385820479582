import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import style from './style.css';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LibraButton from '../../components/button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Check from '@material-ui/icons/CheckCircleOutline';
import CheckBorder from '@material-ui/icons/PanoramaFishEye';
import IconAuto from '../../assets/icons/icon-auto.svg';
import IconMoto from '../../assets/icons/icon-moto.svg';
import IconAutoWhite from '../../assets/icons/icon-auto-white.svg';
import IconMotoWhite from '../../assets/icons/icon-moto-white.svg';
import MainAccessButton from '../../components/MainAccessButton';
import { useHistory } from "react-router-dom";
import Header from '../../components/header';
import Logo from '../../assets/logo.svg';
import Headline from '../../components/Headline/Headline';
import { connect } from 'react-redux';
import { dataSetTipoCotizacion, dataReset } from '../../redux/actions/data';
import { withStyles } from '@material-ui/core/styles';
import { loadState } from '../../localStorage';

const LibraButtonWhite = withStyles(theme => ({
    root: {
		backgroundColor: theme.palette.common.white,
		boxShadow: '0px 1px 1px rgba(0,0,0,0.2)',
        '&:hover': {
			backgroundColor: theme.palette.common.white,
			boxShadow: '0px 1px 1px rgba(0,0,0,0.2)',
		},
	},
	label: {
		color: theme.palette.primary.main
	}
}))(LibraButton);

const Home = ({ tipoCotizacion, dataSetTipoCotizacion, dataReset }) => {
	const history = useHistory();
	const [asegurar, setAsegurar] = useState(tipoCotizacion);
	const [tyc, setTyc] = useState(false);
	const [retomar, setRetomar] = useState(false);

	const handleComenzar = () => {
		return dataReset()
		.then(() => dataSetTipoCotizacion(asegurar))
		.then(() => {
			history.push('/cotizacion')
		});
	}

	const handleRetomar = () => {
		history.push('/cotizacion');
	}

	useEffect(() => {
		const state = loadState();
		setRetomar(state && state.data && state.data.step ? true : false);
	},[]);

	return (
		<Fragment>

			{/* Header */}
			<Header center={(
				<img src={Logo} style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
			)} />

			{/* Banner */}
			<Box px={3} className={style.banner}>
				<Grid container spacing={3} justify={'center'}>
					<Grid item xs={12} sm={9} md={6}>
						<Typography variant={'subtitle1'}>¿Cómo contratar Libra Seguros?</Typography>
						<Typography variant={'h5'}><strong>¡Es súper cómodo! Podés hacer todo en linea, en el día, estés donde estés.</strong></Typography>
					</Grid>
				</Grid>
			</Box>

			{/* Cuerpo principal */}
			<Box px={3} py={5}>
				<Grid container justify={'center'}>
					<Grid item xs={12} sm={9} md={4}>
						<Grid container spacing={4}>
							<Grid item xs={12}>
								<Headline>¿Qué te gustaría asegurar?</Headline>
							</Grid>

							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<MainAccessButton
											label={'MI AUTO'}
											icon={<img src={IconAuto} style={{ display: 'block' }} />}
											activeIcon={<img src={IconAutoWhite} style={{ display: 'block' }} />}
											active={asegurar === 'auto'}
											onClick={evt => setAsegurar('auto')}
										/>
									</Grid>
									<Grid item xs={6}>
										<MainAccessButton
											label={'MI MOTO'}
											icon={<img src={IconMoto} style={{ display: 'block' }} />}
											activeIcon={<img src={IconMotoWhite} style={{ display: 'block' }} />}
											active={asegurar === 'moto'}
											onClick={evt => setAsegurar('moto')}
										/>
									</Grid>
								</Grid>

							</Grid>

							<Grid item xs={12}>
								<FormControlLabel
									onChange={evt => setTyc(evt.nativeEvent.target.checked)}
									style={{ alignItems: 'flex-start' }}
									control={<Checkbox icon={<CheckBorder />} checkedIcon={<Check />} color={'primary'} />}
									label={(
										<Typography style={{ paddingTop: 8 }}>He leído y acepto los <a href="#" target="_blank">términos y condiciones</a> y políticas de privacidad</Typography>
									)}
								/>
							</Grid>
							
							<Grid item xs={12}>
								<Grid container spacing={1}>
									{retomar ? <Grid item xs={12}>
										<LibraButtonWhite
											fullWidth
											color={'secondary'}
											disableElevation={false}
											onClick={handleRetomar}
										>Retomar cotización</LibraButtonWhite>
									</Grid> : null}
									<Grid item xs={12}>
										<LibraButton
											fullWidth
											disabled={!(tyc && asegurar)}
											onClick={handleComenzar}
										>Comenzar</LibraButton>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>

		</Fragment>
	)
}

const mapStateToProps = state => {
	return {
		tipoCotizacion: state.data.tipoCotizacion
	}
}

const mapDispatchToProps = dispatch => {
	return {
		dataSetTipoCotizacion: x => dispatch(dataSetTipoCotizacion(x)),
		dataReset: () => dispatch( dataReset() )
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);