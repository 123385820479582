import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/CheckCircleOutline';
import CheckBorder from '@material-ui/icons/PanoramaFishEye';
import LibraButton from '../../components/button';
import IconChevronRight from '@material-ui/icons/ChevronRight';
import Header from '../../components/header';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import validate from 'validate.js';
import InputMask from 'react-input-mask';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { connect } from 'react-redux';
import { dataSetDatosPoliza } from '../../redux/actions/data';
import Headline from '../../components/Headline/Headline';
import Container from '@material-ui/core/Container';

const DatosPoliza = ({ nombre = '', apellido = '', email = '', telefono = '', dataSetDatosPoliza }) => {
    const history = useHistory();

    const [formValues, setFormValues] = useState({
        nombre: nombre,
        apellido: apellido,
        dni: '',
        fechaNacimiento: '',
        sexo: '',
        email: email,
        telefono: telefono,
        calle: '',
        calleNumero: '',
        pisoDpto: '',
        localidad: '',
        patente: '',
        colorVehiculo: '',
        numeroChasis: '',
        numeroMotor: ''
    });

    const [formErrors, setFormErrors] = useState({});

    const onClickBack = () => {
        history.goBack();
    }

    const formatDate = string => {
        const a = string.split('/');
        return `${a[1]}/${a[0]}/${a[2]}`;
    }

    const handleSubmit = () => {
        dataSetDatosPoliza(formValues).then(() => {
            history.push('/datos-pago');
        });
    }

    useEffect(() => {
        setFormErrors(
            validate(formValues, {
                nombre: {
                    presence: { allowEmpty: false },
                    type: 'string',
                    format: /^[a-z ,.'-]+$/i
                },
                apellido: {
                    presence: { allowEmpty: false },
                    type: 'string',
                    format: /^[a-z ,.'-]+$/i
                },
                dni: {
                    presence: { allowEmpty: false },
                    numericality: {
                        strict: true,
                        onlyInteger: true,
                    }
                },
                email: {
                    presence: { allowEmpty: false },
                    email: true
                },
                telefono: {
                    presence: { allowEmpty: false },
                    type: 'string',
                    format: /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/
                },
                calle: {
                    presence: { allowEmpty: false },
                    type: 'string'
                },
                calleNumero: {
                    presence: { allowEmpty: false },
                    numericality: {
                        strict: true,
                        onlyInteger: true,
                    }
                },
                pisoDpto: {
                    presence: { allowEmpty: false },
                    type: 'string',
                },
                localidad: {
                    presence: { allowEmpty: false },
                    type: 'string',
                },
                patente: {
                    presence: { allowEmpty: false },
                    type: 'string',
                    format: /^([a-zA-Z]{2,3})([0-9]{3})([a-zA-Z]{2})?$/
                },
                colorVehiculo: {
                    presence: { allowEmpty: false },
                    type: 'string',
                },
                numeroChasis: {
                    presence: { allowEmpty: false },
                    type: 'string',
                },
                numeroMotor: {
                    presence: { allowEmpty: false },
                    type: 'string',
                },
            }) || {}
        )
    }, [formValues]);

    return (
        <Box>
            <Header
                left={<IconButton aria-label="upload picture" component="span" onClick={onClickBack}><ArrowBack color={'primary'} /></IconButton>}
            />
            <Box py={5}>
                <Container maxWidth={'sm'}>
                    <Grid container spacing={6}>

                        <Grid item xs={12}>
                            <Headline>¡Felicitaciones! <br />Conseguiste el mejor plán...</Headline>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Box mb={-2}>
                                        <Typography variant={'overline'} display={'block'}>Datos personales</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Nombre"
                                        variant="outlined"
                                        fullWidth
                                        value={formValues.nombre}
                                        onChange={evt => setFormValues({ ...formValues, nombre: evt.target.value })}
                                        error={!!(formErrors.nombre && formValues.nombre)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Apellido"
                                        variant="outlined"
                                        fullWidth
                                        value={formValues.apellido}
                                        onChange={evt => setFormValues({ ...formValues, apellido: evt.target.value })}
                                        error={!!(formErrors.apellido && formValues.apellido)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        fullWidth
                                        helperText={'Tu póliza te va a llegar a este mail apenas termines tu compra.'}
                                        value={formValues.email}
                                        onChange={evt => setFormValues({ ...formValues, email: evt.target.value })}
                                        error={!!(formErrors.email && formValues.email)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Teléfono"
                                        InputProps={{ startAdornment: <InputAdornment position="start">+54</InputAdornment> }}
                                        variant={'outlined'}
                                        fullWidth
                                        placeholder={'Ej: 11 5555 6666'}
                                        helperText={'Sin guiones, ni espacios. Incluye el código de área de tu ciudad.'}
                                        value={formValues.telefono}
                                        onChange={evt => setFormValues({ ...formValues, telefono: evt.target.value })}
                                        error={!!(formErrors.telefono && formValues.telefono)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputMask
                                        label="Fecha de nacimiento"
                                        variant="outlined"
                                        fullWidth
                                        value={formatDate(formValues.fechaNacimiento)}
                                        onChange={evt => setFormValues({ ...formValues, fechaNacimiento: formatDate(evt.target.value) })}
                                        error={!!(formErrors.fechaNacimiento && formValues.fechaNacimiento)}
                                        helperText={'Formato DD/MM/AAAA'}
                                        mask={'99/99/9999'}
                                        maskChar={'-'}
                                    >
                                        {inputProps => <TextField {...inputProps} />}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="DNI"
                                        variant="outlined"
                                        fullWidth
                                        value={formValues.dni}
                                        onChange={evt => setFormValues({ ...formValues, dni: evt.target.value })}
                                        error={!!(formErrors.dni && formValues.dni)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset" error={true} error={!!(formErrors.sexo && formValues.sexo)}>
                                        <FormLabel component="legend">Sexo</FormLabel>
                                        <RadioGroup aria-label="gender" name="gender1" value={formValues.sexo} onChange={evt => setFormValues({ ...formValues, sexo: evt.target.value })} style={{flexDirection: 'row'}}>
                                            <FormControlLabel value="masculino" control={<Radio />} label="Masculino" />
                                            <FormControlLabel value="femenino" control={<Radio />} label="Femenino" />
                                            <FormControlLabel value="otro" control={<Radio />} label="Otro" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Box mb={-2}>
                                        <Typography variant={'overline'} display={'block'}>Ubicación</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Calle"
                                        variant="outlined"
                                        fullWidth
                                        value={formValues.calle}
                                        onChange={evt => setFormValues({ ...formValues, calle: evt.target.value })}
                                        error={!!(formErrors.calle && formValues.calle)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Número"
                                        variant="outlined"
                                        fullWidth
                                        value={formValues.calleNumero}
                                        onChange={evt => setFormValues({ ...formValues, calleNumero: evt.target.value })}
                                        error={!!(formErrors.calleNumero && formValues.calleNumero)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Piso"
                                        variant="outlined"
                                        fullWidth
                                        value={formValues.pisoDpto}
                                        onChange={evt => setFormValues({ ...formValues, pisoDpto: evt.target.value })}
                                        error={!!(formErrors.pisoDpto && formValues.pisoDpto)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Localidad"
                                        variant="outlined"
                                        fullWidth
                                        value={formValues.localidad}
                                        onChange={evt => setFormValues({ ...formValues, localidad: evt.target.value })}
                                        error={!!(formErrors.localidad && formValues.localidad)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Box mb={-2}>
                                        <Typography variant={'overline'} display={'block'}>Datos del vehículo</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Patente"
                                        variant="outlined"
                                        fullWidth
                                        value={formValues.patente}
                                        onChange={evt => setFormValues({ ...formValues, patente: evt.target.value })}
                                        error={!!(formErrors.patente && formValues.patente)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Color"
                                        variant="outlined"
                                        fullWidth
                                        value={formValues.colorVehiculo}
                                        onChange={evt => setFormValues({ ...formValues, colorVehiculo: evt.target.value })}
                                        error={!!(formErrors.colorVehiculo && formValues.colorVehiculo)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Número de chasis"
                                        variant="outlined"
                                        fullWidth
                                        value={formValues.numeroChasis}
                                        onChange={evt => setFormValues({ ...formValues, numeroChasis: evt.target.value })}
                                        error={!!(formErrors.numeroChasis && formValues.numeroChasis)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Número de motor"
                                        variant="outlined"
                                        fullWidth
                                        value={formValues.numeroMotor}
                                        onChange={evt => setFormValues({ ...formValues, numeroMotor: evt.target.value })}
                                        error={!!(formErrors.numeroMotor && formValues.numeroMotor)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <LibraButton
                                fullWidth
                                startIcon={<span style={{ width: 20 }} />}
                                endIcon={<IconChevronRight />}
                                onClick={() => handleSubmit()}
                                disabled={Object.keys(formErrors).length > 0}
                            >Siguiente</LibraButton>
                        </Grid>


                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}

const mapStateToProps = state => {
    return {
        nombre: state.data.nombre,
        apellido: state.data.apellido,
        email: state.data.email,
        telefono: state.data.telefono,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dataSetDatosPoliza: datosPoliza => dispatch(dataSetDatosPoliza(datosPoliza))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatosPoliza);