import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/CheckCircleOutline';
import CheckBorder from '@material-ui/icons/PanoramaFishEye';
import LibraButton from '../../components/button';
import IconChevronRight from '@material-ui/icons/ChevronRight';
import Header from '../../components/header';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import Tarjetas from '../../assets/tarjetas.png';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import InputMask from 'react-input-mask';
import validate from 'validate.js';
import Container from '@material-ui/core/Container';

const DatosPago = () => {
    const history = useHistory();

    const [formValues, setFormValues] = useState({
        tarjetaNro: '',
        tarjetaVto: '',
        titular: '',
        dni: '',
        email: '',
        autoriza: false
    });

    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        setFormErrors(
            validate(formValues, {
                tarjetaNro: {
                    presence: { allowEmpty: false },
                    type: 'string',
                },
                titular: {
                    presence: { allowEmpty: false },
                    type: 'string',
                    format: /^[a-z ,.'-]+$/i
                },
                dni: {
                    presence: { allowEmpty: false },
                    numericality: {
                        strict: true,
                        onlyInteger: true,
                    }
                },
                email: {
                    presence: { allowEmpty: false },
                    email: true
                },
            }) || {}
        )
    }, [formValues]);

    return (
        <Box>
            <Header
                left={<IconButton aria-label="upload picture" component="span" onClick={() => history.goBack()}><ArrowBack color={'primary'} /></IconButton>}
            />
            <Box py={5}>
                <Container maxWidth={'sm'}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant={'h6'} style={{ textAlign: 'center', fontWeight: 'bold' }}>Pagá con Mercado Pago</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} my={-1}>
                                <img src={Tarjetas} />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Box mb={-2}>
                                        <Typography variant={'overline'} display={'block'}>Tarjeta de crédito</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputMask
                                        label="Número de tarjeta"
                                        variant="outlined"
                                        fullWidth
                                        value={formValues.tarjetaNro}
                                        onChange={evt => setFormValues({ ...formValues, tarjetaNro: evt.target.value })}
                                        error={!!(formErrors.tarjetaNro && formValues.tarjetaNro)}
                                        mask={'9999 9999 9999 9999'}
                                        maskChar={' '}
                                    >
                                        {inputProps => <TextField {...inputProps} />}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputMask
                                        label="Vencimiento"
                                        variant="outlined"
                                        fullWidth
                                        value={formValues.tarjetaVto}
                                        onChange={evt => setFormValues({ ...formValues, tarjetaVto: evt.target.value })}
                                        error={!!(formErrors.tarjetaVto && formValues.tarjetaVto)}
                                        mask={'99/99'}
                                        maskChar={'-'}
                                        helperText={'Formato MM/AA'}
                                    >
                                        {inputProps => <TextField {...inputProps} />}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Nombre del titular"
                                        variant="outlined"
                                        fullWidth
                                        value={formValues.titular}
                                        onChange={evt => setFormValues({ ...formValues, titular: evt.target.value })}
                                        error={!!(formErrors.titular && formValues.titular)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="DNI"
                                        variant="outlined"
                                        fullWidth
                                        value={formValues.dni}
                                        onChange={evt => setFormValues({ ...formValues, dni: evt.target.value })}
                                        error={!!(formErrors.dni && formValues.dni)}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Box mb={-2}>
                                        <Typography variant={'overline'} display={'block'}>Email para comprobante de pago</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        fullWidth
                                        value={formValues.email}
                                        onChange={evt => setFormValues({ ...formValues, email: evt.target.value })}
                                        error={!!(formErrors.email && formValues.email)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                style={{ alignItems: 'flex-start' }}
                                control={<Checkbox icon={<CheckBorder />} checkedIcon={<Check />} color={'primary'} />}
                                label={(
                                    <Typography variant={'body2'} style={{ paddingTop: 8 }}>Autorizo a que el primer pago de la póliza se realice vía MercadoPago y el resto por débito automático de mi tarjeta de crédito.</Typography>
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <LibraButton
                                fullWidth
                                startIcon={<span style={{ width: 20 }} />}
                                endIcon={<IconChevronRight />}
                                disabled={Object.keys(formErrors).length > 0}
                                onClick={() => history.push('/descargar-poliza')}
                            >Confirmar pago</LibraButton>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}

export default DatosPago;