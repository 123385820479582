import { h } from 'preact';
import Typography from '@material-ui/core/Typography';

export default ({ children }) => {
    return (
        <Typography 
            variant={'h5'} 
            align={'center'} 
            style={{ fontWeight: 'bold' }}
        >{children}</Typography>
    );
}