import { DATA_SET_ANO, DATA_SET_TIPO_COTIZACION, DATA_SET_MARCA, DATA_SET_MODELO, DATA_SET_EXTRAS, DATA_SET_DATOS_PERSONALES, DATA_SET_INFO_CONTACTO, DATA_RESET, DATA_SET_STEP, DATA_SET_DATOS_POLIZA } from '../constants/data';

export const dataReset = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return resolve(dispatch({
                type: DATA_RESET
            }));
        })
    }
}

export const dataSetTipoCotizacion = x => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return resolve(dispatch({
                type: DATA_SET_TIPO_COTIZACION,
                payload: x
            }));
        })
    };
}

export const dataSetAno = x => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return resolve(dispatch({
                type: DATA_SET_ANO,
                payload: x
            }))
        })
    }
}

export const dataSetMarca = marca => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return resolve(dispatch({
                type: DATA_SET_MARCA,
                payload: marca
            }))
        })
    }
}

export const dataSetModelo = modelo => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return resolve(dispatch({
                type: DATA_SET_MODELO,
                payload: modelo
            }))
        })
    }
}

export const dataSetExtras = extras => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return resolve(dispatch({
                type: DATA_SET_EXTRAS,
                payload: extras
            }))
        })
    }
}

export const dataSetDatosPersonales = datosPersonales => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return resolve(dispatch({
                type: DATA_SET_DATOS_PERSONALES,
                payload: datosPersonales
            }))
        })
    }
}

export const dataSetInfoContacto = infoContacto => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return resolve(dispatch({
                type: DATA_SET_INFO_CONTACTO,
                payload: infoContacto
            }))
        })
    }
}

export const dataSetStep = step => {
    return {
        type: DATA_SET_STEP,
        payload: step
    }
}

export const dataSetDatosPoliza = datosPoliza => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return resolve(dispatch({
                type: DATA_SET_DATOS_POLIZA,
                payload: datosPoliza
            }))
        })
    }
}