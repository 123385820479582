import { h } from 'preact';
import Box from '@material-ui/core/Box'
import Header from '../../components/header';
import HeaderWithSteps from '../../components/HeaderWithSteps/HeaderWithSteps';
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";
import Ano from './Ano/Ano';
import Marca from './Marca/Marca';
import Modelo from './Modelo/Modelo';
import Extras from './Extras/Extras';
import DatosPersonales from './DatosPersonales/DatosPersonales';
import InformacionContacto from './InformacionContacto/InformacionContacto';
import Confirmacion from './Confirmacion/Confirmacion';
import Editor from '../../components/Editor/Editor';
import { connect } from 'react-redux';
import { dataSetStep } from '../../redux/actions/data';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import EditorStepper from '../../components/EditorStepper/EditorStepper';
import Container from '@material-ui/core/Container';

const Cotizacion = ({ step, dataSetStep }) => {
    const history = useHistory();

    const _renderStep = step => {
        switch (step) {
            case 1:
                return <Ano />
            case 2:
                return <Marca />
            case 3:
                return <Modelo />
            case 4:
                return <Extras />
            case 5:
                return <DatosPersonales />
            case 6:
                return <InformacionContacto />
            case 7:
                return <Confirmacion />
        }
    }

    const handleClickBack = () => {
        if(step > 1){
            dataSetStep(step - 1);
        }else{
            history.push('/');
        }
    }

    return (
        <Box style={{ paddingBottom: 40 }}>
            <HeaderWithSteps step={step} onClickBack={handleClickBack} />
            <Container maxWidth={'md'}>
                <Box py={5}>
                    <Grid container spacing={3} justify={'center'}>
                        <Grid item xs={12} sm={9} md={7}>
                            {_renderStep(step)}
                        </Grid>
                        <Grid item xs={12} sm={9} md={5}>
                            <Box display={{xs: 'none', md: 'block'}} pl={5}>
                                <Paper elevation={1} style={{borderRadius: 10, overflow: 'hidden'}}>
                                    <EditorStepper />
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Box display={{xs: 'block', md: 'none'}}>
                <Editor />
            </Box>
        </Box>
    )
};

const mapStateToProps = state => {
    return {
        step: state.data.step
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dataSetStep: step => dispatch(dataSetStep(step))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cotizacion)