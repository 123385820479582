import { h, Fragment } from 'preact';
import { forwardRef } from 'preact/compat';
import { useState, useEffect, useRef } from 'preact/hooks';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Header from '../../components/header';
import Paper from '@material-ui/core/Paper';
import LibraButton from '../../components/button';
import Button from '@material-ui/core/Button';
import IconChevronRight from '@material-ui/icons/ChevronRight';
import IconClose from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { useHistory } from 'react-router-dom';
import { propuestaGetPlanes } from '../../redux/actions/propuesta';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import 'swiper/swiper.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import IconHelp from '@material-ui/icons/HelpOutline';
import Container from '@material-ui/core/Container'
import Headline from '../../components/Headline/Headline';

const SkeletonView = () => {
    return (
        <Fragment>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Typography variant={'subtitle1'}>
                    <Skeleton variant="text" width={'80%'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    <Skeleton variant="text" width={'60%'} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                </Typography>
            </Grid>
            {['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''].map(() => (
                <Grid item xs={12} sm={6} md={4}>
                    <Paper elevation={0}>
                        <Box p={2} style={{ textAlign: 'center' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant={'subtitle1'}>
                                        <Skeleton variant={'text'} width={100} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                                    </Typography>
                                    <Typography variant={'h4'}>
                                        <Skeleton variant={'text'} width={170} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                                    </Typography>
                                    <Typography variant={'subtitle1'}>
                                        <Skeleton variant={'text'} width={170} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                                    </Typography>
                                    <Typography variant={'subtitle1'}>
                                        <Skeleton variant={'text'} width={100} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            ))}
        </Fragment>
    )
}

const Producto = ({ featured = false, onClickDetalle }) => {
    const history = useHistory();
    return (
        <Paper elevation={0} style={{ border: featured ? 'solid 1px var(--orange)' : 'solid 1px #A3A3A3' }}>
            <Box p={3} style={{ textAlign: 'center' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant={'h6'} style={{ fontWeight: 'bold', color: featured ? 'var(--orange)' : 'initial', lineHeight: 1, marginBottom: 16 }}>OrangeTime</Typography>
                        <Typography variant={'h4'} style={{ fontWeight: 'bold', lineHeight: 1 }}>$6.009<span style={{ fontSize: 16 }}>/mes</span></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                        <Box mt={3}>
                            <Box mb={2}>
                                <Typography variant={'subtitle1'} align={'left'} style={{fontWeight: '700'}} color={'primary'}>Este plan <u>incluye</u>:</Typography>
                                <Typography variant={'body1'} align={'left'}>• Responsabilidad civil, incendio total, robo, hurto total.</Typography>
                            </Box>
                            <Box mb={2}>  
                                <Typography variant={'subtitle1'} align={'left'} style={{fontWeight: '700'}} color={'primary'}>Este plan <u>no incluye</u>:</Typography>
                                <Typography variant={'body1'} align={'left'}>• Robo, incendio, daños parciales, destrucción o daños totales por accidente, cristales, cerraduras, granizo.</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            <Button 
                                onClick={onClickDetalle} 
                                style={{ color: 'black', fontSize: 14, textDecoration: 'underline', fontWeight: 'bold', marginBottom: 16, }}
                            >Detalle de la cobertura +</Button>
                        </Box>
                        <Box>
                            <LibraButton
                                startIcon={<span style={{ width: 10 }} />}
                                endIcon={<IconChevronRight />}
                                color={featured ? 'primary' : 'secondary'}
                                onClick={() => history.push('/datos-poliza')}
                            >Comprar</LibraButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}

const PlanesView = ({ planes }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [initialSlide, setinitialSlide] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const swiperRef = useRef(null);

    return (
        <Fragment>
            <Grid item xs={12}>
                <Headline>Juan Carlos, podemos ofrecerte<br /> los siguientes planes:</Headline>
            </Grid>
            {planes.map((plan, index) => (
                <Grid key={plan.id} item xs={12} sm={6} md={4}>
                    <Producto 
                        id={plan.id} 
                        featured={plan.featured}
                        onClickDetalle={() => {
                            setinitialSlide(index);
                            setModalOpen(true);
                        }}
                    />
                </Grid>
            ))}
            <Dialog fullScreen open={modalOpen} TransitionComponent={Transition}>
                <Header
                    left={<IconButton aria-label="upload picture" component="span" onClick={evt => setModalOpen(false)}><IconClose color={'primary'} /></IconButton>}
                    // center={<Typography style={{ textAlign: 'left', fontWeight: 'bold' }}>{`Plan ${activeIndex} de ${planes.length}`}</Typography>}
                    right={
                        <Box>
                            <IconButton component="span" onClick={evt => swiperRef.current.swiper.slidePrev()}><ArrowBack color={'secondary'} /></IconButton>
                            <IconButton component="span" onClick={evt => swiperRef.current.swiper.slideNext()}><ArrowForward color={'secondary'} /></IconButton>
                        </Box>
                    }
                />
                <Container maxWidth={'md'}>
                    <Swiper
                        ref={swiperRef}
                        spaceBetween={0}
                        slidesPerView={1}
                        centeredSlides={true}
                        initialSlide={initialSlide}
                        loop={true}
                        onSlideChange={evt => setActiveIndex(evt.activeIndex)}
                    >
                        {planes.map(plan => (
                            <SwiperSlide key={plan.id}>
                                <Box p={3}>
                                    <Typography color={'primary'} variant={'h6'} style={{marginBottom: '1em', fontWeight: 'bold'}} fontWeight={700}>Lorem Ipsum</Typography>
                                    <Typography component={'p'} style={{marginBottom: '1.4em'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus neque tortor, tristique in ultrices volutpat, euismod ut neque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</Typography>
                                    <Typography component={'p'} style={{marginBottom: '1.4em'}}>Interdum et malesuada fames ac ante ipsum primis in faucibus. Maecenas a justo sed arcu porttitor scelerisque. Ut sollicitudin faucibus mi, ut imperdiet lacus. Nullam id iaculis metus. Etiam lacus magna, pretium ut leo eu, vulputate ultricies neque. Proin maximus neque id elit malesuada imperdiet. In hac habitasse platea dictumst.</Typography>
                                    <LibraButton 
                                        fullWidth 
                                        onClick={evt => setModalOpen(false)}
                                        startIcon={<span style={{ width: 10 }} />}
                                        endIcon={<IconChevronRight />}
                                    >Comprar</LibraButton>
                                </Box>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Container>
            </Dialog>
        </Fragment>
    )
}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [planes, setPlanes] = useState([]);

    const onClickBack = () => {
        history.goBack();
    }

    useEffect(() => {
        setLoading(true);
        propuestaGetPlanes().then(res => {
            setPlanes(res.data);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [loading]);

    return (
        <Fragment>
            <Header
                left={<IconButton aria-label="upload picture" component="span" onClick={onClickBack}><ArrowBack color={'primary'} /></IconButton>}
                center={<Typography style={{ textAlign: 'left', fontWeight: 'bold' }}>Elegí tu plan</Typography>}
            />
            <Box py={5}>
                <Container maxWidth={'md'}>
                    <Grid container spacing={4}>
                        {loading ? <SkeletonView /> : <PlanesView planes={planes} />}
                    </Grid>
                </Container>
            </Box>
        </Fragment>
    )
}